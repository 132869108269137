import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import { Box, Text } from 'grommet';
import ProductIntro from 'components/productIntro';
import SectionTitle from 'components/title/sectionTitle';
import { RequestBar, RequestBarExtended } from 'components/requestBar';
import { ImageStack } from 'components/pageCards';
import Intro from '../../content/products/ophthosimEducatorsPortal/intro.mdx';
import Intro2 from '../../content/products/ophthosimEducatorsPortal/intro2.mdx';
import Develop from '../../content/products/ophthosimEducatorsPortal/develop.mdx';
import Features from '../../content/products/ophthosimEducatorsPortal/features.mdx';
import TrackAndMonitor from '../../content/products/ophthosimEducatorsPortal/trackAndMonitor.mdx';
import InformedDecision from '../../content/products/ophthosimEducatorsPortal/informedDecision.mdx';
import Footer from '../../content/products/ophthosimEducatorsPortal/footer.mdx';

const OphthosimEducatorsPortal = ({ data }) => (
  <Layout>
    <Head pageTitle={data.ophthosimEducatorsPortalJson.title} />
    <Box fill="horizontal">
      <SectionTitle title="OtoSim and OphthoSim Mobile Educators Portals™" />
      <Box width="xlarge" alignSelf="center">
        <ProductIntro>
          <Intro />
        </ProductIntro>
        <RequestBar />
        <ImageStack
          image={data.ophthosimEducatorsPortalJson.pagePic1}
          textColor="white"
          textAlign="right"
        >
          <Text size="large">
            <Intro2 />
          </Text>
        </ImageStack>
        <Box background="light-1" pad="large">
          <Features />
        </Box>
        <ImageStack
          image={data.ophthosimEducatorsPortalJson.pagePic2}
          textColor="dark-1"
          textAlign="left"
        >
          <Text size="medium">
            <Develop />
          </Text>
        </ImageStack>
        <ImageStack
          image={data.ophthosimEducatorsPortalJson.pagePic3}
          textColor="dark-1"
          textAlign="right"
        >
          <Text size="medium">
            <TrackAndMonitor />
          </Text>
        </ImageStack>
        <ImageStack
          image={data.ophthosimEducatorsPortalJson.pagePic4}
          textColor="dark-1"
          textAlign="left"
        >
          <Text size="medium">
            <InformedDecision />
          </Text>
        </ImageStack>
        <Box background="light-1" pad="large">
          <Footer />
        </Box>

        <RequestBarExtended />
      </Box>
    </Box>
  </Layout>
);

OphthosimEducatorsPortal.propTypes = {
  data: PropTypes.object.isRequired,
};

export default OphthosimEducatorsPortal;

export const query = graphql`
  query OphthosimEducatorsPortalQuery {
    ophthosimEducatorsPortalJson {
      title
      pagePic1 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pagePic2 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pagePic3 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pagePic4 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
