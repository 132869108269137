import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Link } from 'gatsby';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<MDXTag name="p" components={components}>{`The Mobile Educators Portals™, `}<Link to="/otosim-mobile">{`OtoSim Mobile™`}</Link>{` and `}<Link to="/ophthosim-mobile">{`OphthoSim Mobile™`}</Link>{` simulators can also be used in conjunction with the
OtoSim 2™ and the OphthoSim™ Simulation Systems to further enhance learning outcomes.
We would be happy to offer advice on best possible configurations to suit your educational needs and budget..`}</MDXTag></MDXTag>

export const _frontmatter = {};

  