import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Heading} from 'grommet';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<Heading level="3" size="small" responsive={true} alignSelf="center">
    Develop and customize your otology and ophthalmology curricula:
</Heading>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Username and password for personalized access to our web-based cloud Portal`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Access a library of over 400 high-quality otologic and over 200 retinal images respectively complete with annotations and robust content`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Build and customize lectures and presentations for each group of learners`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Create and deliver quizzes and exams to assess both anatomical knowledge and diagnostic skills of your students`}</MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  