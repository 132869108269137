import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`The OtoSim Mobile Educators Portal™ and the OphthoSim Mobile Educators Portal™ are online educational platforms for mobile otoscopy and ophthalmoscopy simulation.
Most educators recommend simulation to improve their students level of comfort and confidence, to enhance the acquisition and retention of knowledge and to acquire the skills required to perform otoscopy and direct ophthalmoscopy. This will improve diagnostic performance and ultimately lead to better patient outcomes.`}</MDXTag></MDXTag>

export const _frontmatter = {};

  